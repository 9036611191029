import React from 'react'
import { Link } from 'gatsby'
import logo from "../images/newlogo.png"
const Header = () => (
  <div 
    style={{
      marginBottom: '1.45rem'
    }}
  >
  <div
  style={{
    margin:'0 auto',
    maxwidth: 960,
    padding: '1.45rem 1.0875rem',
    //padding: '0px 6.0875rem 1.45rem',
    position: 'relative',
    //left: '100px'
  }}
  >
    
      
        <Link
          to="/"
         >
           <img src={logo} alt="dragon logo"/>
        </Link>
      
  </div>
  </div>
)

export default Header
