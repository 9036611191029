import React from 'react'
//import Link from 'gatsby-link'
//import { Link } from 'gatsby'
//import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu'
import './nav.css'

const Nav = () =>(
    <div class="navbar">
    <a class="active" href="/"><i class="fa fa-fw fa-home"></i>Home</a>
    <a href="/about"><i class="fa fa-fw fa-about"></i>About</a>
    <a href="/achievements"><i class="fa fa-fw fa-achieve"></i>Achievements</a>
    <a href="/participation"><i class="fa fa-fw fa-participation"></i>Participation</a>
    <a href="/contact"><i class="fa fa-fw fa-envelope"></i>Contact</a>


    </div>
)

export default Nav

//to="/">Home
//to="/about">About Us 
//to="/achievements">Achievements
//to="/participation">Participation 
//to="/contact">Contact 