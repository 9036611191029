import React from 'react'
//import { Link } from 'gatsby'
import Bridge from '../images/bridge.jpeg'
import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <h1>About Waterford Dragon Warriors</h1>
    <img  src={Bridge} alt="dragon bridge"/>

    

    <p>Waterford Dragon Warriors was founded in the summer of 2013 by an inspirational lady called Marcella Sweeney.
      Marcella discovered the existence of dragon boating following a conversation with consultant breast cancer surgeon Mr. Constantino Castineira.
      She duly headed off to Athy to try it out. It was in Athy that she met the Plurabelle Paddlers, who inspired her to set up a club in the South East.

    </p>

       <p>Following an open meeting in the Solas Centre in July of 2013, 6 breast cancer survivors travelled each week to Clonmel to learn the 
         sport from Mr. Castineira (Tino). In their spare time, they devoted every minute raising money, chasing sponsors and sorting a berth and premises before finally launching our boat and club in April 2014.
         We were privileged to have with us that day both Tino and a large group of Plurabelle Paddlers who travelled down especially to see our dream realised.
       </p>

       <p>Waterford Dragon Warriors is open to everyone over 18. Breast Cancer Survivors, Supporters, Family and Friends - both male and female.
         Although Marcella sadly lost her battle, her legacy remains.
       </p>

       <p>In July 2018, 3 members of the club took part in the International Breast Cancer Survivor's Participatory Dragon Boat Festival in Florence
         as part of a composite team from Ireland called the Wild Atlantic Warriors. They were made up of survivors from Waterford, Cork, Mayo and Donegal.
         This regatta is held every 4 years, when survivors from around the world come together and compete.
         This year there were approximately 4000 competitors with 4 teams from Ireland taking part.
       </p>
        <p>
          It was the icing on the cake for us when our very own Ann Walshe was given the honour of carrying the Irish flag at the closing ceremony.
          Ann, at 74, showed that not only is there life after cancer but also that age is no barrier to competing/paddling.
        </p>

        <p>
          Since 2014 our club has grown. We now have 2 dragon boats, 1 safety boat 
          and 40+ members. We train 2-3 times per week between the months of March and November.
          Monday evening training is usually for new members, the first session is free with subsequent sessions 
          costing €5 per person. We welcome new members and people who just want to try it for the first time.
        </p>

  </Layout>
)

export default SecondPage
